import type { VueInstance } from "@vueuse/core";
import type { Ref } from "vue";
import { useFocusableElements } from "./useFocusableElements";

export const useFocusTrap = (
  targetElement: Ref<HTMLElement | SVGElement | VueInstance | undefined>,
) => {
  const {
    firstFocusableElement,
    lastFocusableElement,
    updateFocusableElements,
  } = useFocusableElements(targetElement);

  function isChildOfElement(element: Element, child: Element): boolean {
    const childIsElement = element === child;
    if (childIsElement) {
      return true;
    } else if (child.parentElement) {
      return isChildOfElement(element, child.parentElement);
    }
    return false;
  }

  function keyHandler(e: KeyboardEvent) {
    const isTabPressed = e.key === "Tab";
    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      if (
        firstFocusableElement.value &&
        lastFocusableElement.value &&
        document.activeElement &&
        isChildOfElement(firstFocusableElement.value, document.activeElement)
      ) {
        lastFocusableElement.value.focus();
        e.preventDefault();
      }
    } else {
      if (
        lastFocusableElement.value &&
        firstFocusableElement.value &&
        document.activeElement &&
        isChildOfElement(lastFocusableElement.value, document.activeElement)
      ) {
        firstFocusableElement.value.focus();
        e.preventDefault();
      }
    }
  }

  function initFocusTrap() {
    updateFocusableElements();
    if (document) {
      document.addEventListener("keydown", keyHandler);
    }
  }

  function clearFocusTrap() {
    if (document) {
      document.removeEventListener("keydown", keyHandler);
    }
  }

  return {
    initFocusTrap,
    clearFocusTrap,
    updateFocusableElements,
  };
};
